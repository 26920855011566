@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


/* Global layout adjustments */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto; /* Prevent global scrolling */
  font-family: 'DM Sans', sans-serif;
}

body.chat-active .main-content {
  overflow: hidden !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700; /* Bold for headings */
}

p, span, a, button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400; /* Normal weight for body text */
}

strong {
  font-weight: 500; /* Medium weight for strong text */
}

.app {
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-template-rows: 80px 1fr;
  height: 100vh; /* Full viewport height */
  overflow: auto; /* No layout-level scrollbars */
}

/* Collapsed sidebar */
.app.collapsed {
  grid-template-columns: 60px 1fr; /* Collapsed width */
}

.app.collapsed .navbar ul li a {
  display: none; /* Hide links when collapsed */
}

/* Scroll the entire component content */
.main-content {
  height: calc(100vh - 80px); /* Full height minus topbar */
  overflow-y: auto !important; /* Enable vertical scrolling for entire content */
  padding: 20px;
  box-sizing: border-box;
}

/* Remove any scrollbars from individual containers */
.container, .forecasting-container, .on-demand-container, .form-container, .output-container {
  height: auto; /* Let containers expand naturally */
  overflow: auto; /* No individual scrollbars */
  padding: 0;
  margin: 0;
}


/* Topbar */
.topbar {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  background-color: #040e2e;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

/* Navbar */
.navbar {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  background-color: #040e2e;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.navbar-logo-container img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.navbar-logo-container span {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.navbar ul li {
  text-align: center;
  padding: 10px 0;
}

/* Regular navigation links */
.navbar ul li a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;  /* Pointer cursor on hover */
}

.navbar ul li a:hover {
  background-color: #455bf1;
}

/* Active state for navigation links */
.activeNav {
  background-color: white;
  color: #455bf1 !important;
}

.active {
  background-color: #455bf1;
}

/* Logout link styling (same as other nav items) */
.navbar ul li a.logout-link {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;  /* Ensure pointer cursor */
}

/* Hover effect for Logout link */
.navbar ul li a.logout-link:hover {
  background-color: #455bf1;
}


/* Main content */
/* Left-hand side tabs */
.getting-started {
  display: flex;
}

.left-tabs {
  width: 200px;
  background-color: #f4f4f4;
  border-right: 2px solid #eee;
  padding: 10px 0;
}

.left-tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.left-tabs li {
  padding: 10px 20px;
  cursor: pointer;
}

.left-tabs li.active {
  background-color: #040e2e;
  color: white;
}

.left-tabs li:hover {
  background-color: #455bf1;
  color: white;
}

.content-area {
  padding: 20px;
  flex: 1;
}

.main-content {
  height: calc(100vh - 80px); /* Full height minus the topbar */
  overflow-y: auto; /* Enable scrolling for the entire content area */
  padding: 20px; /* Ensure padding around the content */
}

.container, .form-container, .output-container, .forecasting-container {
  height: auto;
  overflow: visible; /* Prevent individual scrollbars */
  padding: 0;
  margin: 0;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

.tabs button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  outline: none;
  border-bottom: 3px solid transparent; /* Straight line border on the bottom */
  border-radius: 0; /* No rounded corners */
}

.tabs button.active {
  border-bottom: 3px solid #455bf1; /* Active tab underline */
  color: #455bf1;
}

.tabs button:hover {
  border-bottom: 3px solid #455bf1; /* Hover effect */
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Section containers */
.form-container, .output-container {
  width: 100%; /* Occupy full width of the column */
  max-width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is included in width calculations */
  margin: 0; /* No extra margin inside the containers */
  overflow: hidden;
}

.output-container {
  max-width: 100%;
}



/* Section headers for Input and Output */
.form-container h2,
.output-container h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem; /* Ensure headers are bold and slightly larger */
  font-weight: bold; /* Bold for section headers */
  color: #333;
  margin-bottom: 15px;
}



/* Labels for input fields and output sections */
.form-container label,
.response-container h2,
.logs-container h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem; /* This matches the main body text size */
  font-weight: normal; /* Normal weight for labels */
  color: #333; /* Consistent color */
  margin-bottom: 10px; /* Consistent margin */
}

/* Drag-and-Drop Upload Field */
.dropzone {
  width: 100%;
  height: 100px;
  border: 2px dashed #cccccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  background-color: #f9f9f9;
}

.dropzone.active {
  border-color: #040e2e;
  background-color: #e0e0e0;
}

.dropzone p {
  color: #666;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  margin: 0;
}

/* Input field spacing */
.form-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.form-container input[type="text"],
.form-container input[type="file"],
.form-container button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

/* Code block styling */
.code-container {
  margin-top: 20px;
}

.code-container pre {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.9rem;
  overflow-x: auto;
}

.code-container code {
  font-family: 'Courier New', Courier, monospace;
  color: #333;
  white-space: pre-wrap; /* Wrap long lines */
}

/* Input field styling */
.form-container input[type="text"],
.form-container input[type="file"],
.form-container select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: #333;
}

/* Add focus styling */
.form-container input[type="text"]:focus,
.form-container input[type="file"]:focus,
.form-container button:focus,
.form-container select:focus {
  border-color: #040e2e;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Forecasting Container */
.forecasting-container {
  background-color: #f9f9f9;
}

/* Forecasting Title */
.forecasting-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Filter Container */
.filter-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Dropdown Container */
.dropdown-container {
  flex: 1;
  margin-right: 20px;
}


.dropdown-container label {
  display: block;
  font-size: 1rem; /* Ensure this matches the On-Demand label font size */
  font-family: 'Open Sans', sans-serif; /* Matching the font-family used in On-Demand */
  color: #333; /* Ensure this matches the On-Demand label color */
  margin-bottom: 5px;
  font-weight: normal; /* Match the font weight as well */
}


.dropdown-container select,
.dropdown-container input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Button Container */
.button-container button {
  margin-right: 10px; /* Add space between buttons */
}

/* Chart Container */
.chart-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height:500px;
}

.forecasting-container {
  padding: 20px;
  padding-top: 5px;
  background-color: #f9f9f9;
}

.forecasting-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Grid layout for dropdowns */
.filter-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.dropdown-container label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

/* Tabs and buttons aligned on the same row */
.tabs-and-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs {
  display: flex;
}

.tabs button {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
}

.tabs button.active {
  border-bottom: 3px solid #040e2e;
  font-weight: bold;
}

.button-container button {
  margin-right: 10px; /* Optional: add spacing between buttons */
}


.forecast-button {
  background-color: white; /* White background for the secondary action button */
  color: #455BF1; /* Correct blue color for text */
  border: 2px solid #455BF1; /* Blue border */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: normal;
  border-radius: 8px;
}

.forecast-button:hover {
  background-color: #f0f0f0; /* Light grey on hover */
}

.download-button {
  background-color: #455BF1; /* Solid blue background for primary action button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: normal;
  border-radius: 8px;
}

.download-button:hover {
  background-color: #3748c8; /* Darker blue on hover */
}


.chart-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map-container {
  position: relative;
  width: 100%;
  height: 500px; /* Keep the fixed height for the map */
  margin: 0 0 20px 0; /* Bottom margin to add spacing between map and next section */
  padding: 0;
  border-radius: 8px;
  box-sizing: border-box;
}


/* Additional styles for the map */
#map {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Handle container changes on collapse */
.app.collapsed .map-container {
  width: 100%; /* Ensure map container remains at 100% width */
}

.app.collapsed #map {
  width: 100%; /* Ensure the map maintains full width within the collapsed layout */
}

.mapboxgl-canvas {
  position: relative !important; /* Fix map rendering issue */
}

.mapboxgl-ctrl-group {
  position: absolute; /* Ensure buttons are positioned relative to the map */
  right: 10px; /* Align to the right edge of the map */
  top: 10px; /* Align to the top edge of the map */
  z-index: 1; /* Make sure it stays above the map */
}

/* Ensure the "Click the map to draw a polygon" box stays static on the map */
.calculation-box {
  position: absolute; /* Position it relative to the map, not the page */
  bottom: 40px; /* Adjust placement as needed */
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  width: 150px;
  text-align: center;
  z-index: 1; /* Ensure it stays on top of the map */
}

/* Ensure the mapbox controls (trash/polygon) are correctly aligned */
.mapboxgl-ctrl-group button {
  margin: 5px; /* Optional: space between buttons */
}


.customer-info {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.customer-info h3 {
  margin-bottom: 10px;
}

.customer-info p {
  margin: 5px 0;
}

.mapboxgl-popup-content label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.mapboxgl-popup-content input {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.mapboxgl-popup-content button {
  display: block;
  width: 100%;
  padding: 5px;
  background-color: #455BF1;
  color: white;
  border: none;
  cursor: pointer;
}

.mapboxgl-popup-content button:hover {
  background-color: #3448d4;
}

/* Adjust the popup's width */
.mapboxgl-popup-content {
  width: 250px; /* You can adjust this width to fit your design */
}

/* Ensure input fields fit inside the popup */
#polygonName, #polygonDescription {
  width: 100%; /* Make sure input fields are 100% of the popup width */
  box-sizing: border-box; /* Ensure padding is included in the total width */
}

/* Style for the collapse icon container (li element) */
.collapse-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adjust the padding as needed */
  margin-bottom: 10px; /* Adjust the space below the icon */
}

/* Style for the collapse button */
.collapse-button {
  background: none; /* Removes any default background */
  border: none; /* Removes border */
  cursor: pointer; /* Show pointer cursor on hover */
  color: #888; /* Default color of the collapse icon */
  font-size: 1.5rem; /* Size of the icon */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.collapse-button:hover {
  color: #fff; /* Change color on hover */
}

/* Icon SVG inside the button */
button.collapse-button svg {
  display: block;
  width: 24px; /* Customize icon size */
  height: 24px;
}

.custom-popup {
  position: absolute;
  top: 20px; /* Adjust top positioning as necessary */
  right: 20px; /* Always aligned to the right-hand edge */
  width: 300px; /* Adjust the width of the popup */
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it stays on top of other elements */
}

/* Main container to occupy the full viewport height */
.markdown-container {
  display: flex;
  flex-direction: row; /* Align navigation and content side by side */
  height: 100%; /* Ensure the container fills the viewport */
  overflow: hidden; /* Prevent scrolling for the outer container */
}

/* Navigation panel */
.markdown-nav {
  flex: 0 0 250px; /* Fixed width for navigation */
  background-color: #f4f4f4;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100vh; /* Full height of the viewport */
  overflow-y: auto; /* Allow the navigation panel to scroll independently if it overflows */
}

/* Scrollable markdown content */
.markdown-content {
  flex: 1; /* Fill the remaining space for content */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100vh; /* Adjust the height to account for padding or other elements */
  overflow-y: auto; /* Enable scrolling for markdown content */
  box-sizing: border-box;
}

/* Code block styling */
.markdown-content pre {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.9rem;
  overflow-x: auto;
}

.markdown-content code {
  font-family: 'Courier New', Courier, monospace;
  background-color: #e0e0e0;
  padding: 2px 5px;
  border-radius: 4px;
  color: #d63384;
}

/* Add spacing for inline code */
.markdown-content p code {
  margin: 0 2px;
  display: inline-block;
}

.markdown-content img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Optional: Center the image */
}

@media (max-width: 768px) {
  .markdown-container {
    flex-direction: column;
  }

  .markdown-nav {
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    max-height: none;
  }

  .markdown-content {
    max-height: none;
  }
}

.markdown-content h1, .markdown-content h2 {
  font-weight: bold;
  margin-top: 20px;
}

.markdown-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.markdown-content p {
  line-height: 1.6;
  margin-bottom: 20px;
}


.settings .tabs {
  display: flex;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

.settings .tabs button {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
}


/* General tab styling */
.tabs {
  display: flex;  /* Align the tabs horizontally */
  justify-content: flex-start;  /* Align tabs to the left */
  margin: 0;  /* Remove any unexpected margin */
  padding: 0;  /* Remove any unexpected padding */
  border-bottom: 2px solid #ddd;  /* A subtle line to separate tabs from content */
}

.tabs button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  outline: none;
}

.tabs button.active {
  border-bottom: 3px solid #455bf1;  /* Underline the active tab */
  color: #455bf1;  /* Highlight the active tab */
}

.tabs button:hover {
  color: #455bf1;  /* Hover effect to indicate interactivity */
}

.settings-content {
  padding-top: 10px;  /* Ensure there's space between the tabs and content */
}


/* Wide table styling for client details */
.client-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.client-details-table th,
.client-details-table td {
  padding: 15px;
  border: 1px solid #e0e0e0;
  text-align: left;
}

.client-details-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.client-details-table td {
  background-color: #fff;
}

.edit-icon {
  color: #455bf1;
  text-decoration: none;
}

.edit-icon:hover {
  text-decoration: underline;
}

/* Section headers for Service Providers and Devices */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.add-link {
  color: #455bf1;
  text-decoration: none;
}

.add-link:hover {
  text-decoration: underline;
}

/* Service Providers Table */
.service-provider-table {
  margin-bottom: 20px;
}

.provider-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.provider-row p {
  margin: 5px 0;
}

.provider-row .edit-icon {
  color: #455bf1;
  text-decoration: none;
}

/* Devices Table */
.device-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.device-table th,
.device-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.device-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.device-table .status {
  background-color: #ddd;
  padding: 3px 8px;
  border-radius: 4px;
  color: #333;
}

.device-table .edit-icon {
  color: #455bf1;
  text-decoration: none;
}

.device-table .edit-icon:hover {
  text-decoration: underline;
}

/* Customers Table */
.customers-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.customers-table th,
.customers-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.customers-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  background-color: #455bf1;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button {
  background-color: #455BF1;  /* Blue background for primary button */
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.submit-button:hover {
  background-color: #3748c8;  /* Darker blue on hover */
}


.response-window,
.logs-window {
  white-space: pre-wrap !important;  /* Ensure long text wraps */
  word-wrap: break-word;             /* Break long words */
  max-width: 100%;                   /* Keep the content inside the parent container */
  height: 200px;
  overflow-wrap: break-word;         /* Break text if necessary */
  overflow-y: scroll;  /* Scrollbar for long content */  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* Full-page layout with proper spacing */
.chat-container {
  display: flex;
  /*top:80px;*/
  flex-direction: column;
  height: calc(100vh - 120px); /* Adjusted to start below the topbar */
  width: 100%;
  background-color: #ffffff;
}

/* Chat Header */
.chat-header {
  text-align: left;
  font-weight: bold;
  /*padding: 15px;*/
  background-color: #ffffff;
  border-bottom: 1px solid #fff;
}

/* Chat Messages */
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  max-height: calc(100vh - 120px); /* Ensures messages don't overflow */
}


/* Placeholder Text */
.chat-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.2rem;
  color: #888;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  pointer-events: none; /* Ensures it doesn’t interfere with interactions */
}

/* Chat Message Container */
.chat-message {
  max-width: 75%;
  padding: 12px;
  border-radius: 15px;
  margin-bottom: 60px;
  line-height: 1.4;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

/* User Messages (Align Right) */
.chat-message.user {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  text-align: right;
  border-radius: 18px 18px 0px 18px;
  margin-left: auto; /* Push user messages to the right */
  justify-content: flex-end;
  width: 35%;
}

/* Bot Messages (Align Left) */
.chat-message.bot {
  align-self: flex-start;
  background-color: #f1f1f1;
  color: black;
  text-align: left;
  border-radius: 18px 18px 18px 0px;
  margin-right: auto; /* Push bot messages to the left */
  justify-content: flex-start;
}


/* Input Field */
.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 25px;
  background: #efefef;
  outline: none;
  font-size: 1rem;
}

/* Chat Input Area */
.chat-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
}

/* Display uploaded file names */
.chat-file {
  font-size: 14px;
  color: #444;
  margin-top: 4px;
}


/* Multi-line Text Input (Textarea) */
.chat-textarea {
  flex-grow: 1;
  border: none;
  padding: 12px;
  font-size: 16px;
  outline: none;
  border-radius: 20px;
  background-color: #f3f3f3;
  resize: none;
  min-height: 40px;
  max-height: 150px;
  overflow-y: auto;
}

/* Ensure smooth appearance */
.chat-textarea:focus {
  background-color: #e9e9e9;
}

/* Custom Plus Button (File Upload) */
.file-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

/* Make the circle and plus sign dark */
.file-upload-button svg {
  width: 32px;
  height: 32px;
}

/* Hover effect */
.file-upload-button:hover svg circle {
  stroke: #222; /* Darker stroke */
}

.file-upload-button:hover svg line {
  stroke: #222;
}

/* Hide default file input */
.file-input {
  display: none;
}

/* Send Button */
.send-button {
  background: #5a54f8;
  color: white;
  border: none;
  margin-left: 10px;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
}

.send-button:hover {
  background: #4b47e0;
}
